img {
  width: 100%;
}

.landing {
  position: relative;
  background: url("./img/showcase.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  margin-top: -24px;
  margin-bottom: -50px;
}

.landing-inner {
  padding-top: 80px;
}

.dark-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.card-form {
  opacity: 0.9;
}

.latest-profiles-img {
  width: 40px;
  height: 40px;
}

.form-control::placeholder {
  color: #bbb !important;
}

.dashboard p {
  margin-bottom: 0;
  font-size: 14px;
}

.background-custom {
  background-color: rgb(212, 249, 255);
}

@media print {
  @page {
    size: landscape;
  }

  .pagebreak {
    page-break-before: always;
  } /* page-break-after works, as well */
}

.table-fps {
  position: absolute;
  bottom: 0;
  margin-left: 0;
  margin-right: 0;
}

.fps td {
  padding: 0;
  padding-top: 5px;
  padding-right: 5px;
  /* border: 3px solid red; */
  font-size: 10px;
  line-height: 11px;
}
